<template>
  <div class="container">
    <div class="my-4">

      <div v-if="loading" class="d-flex justify-content-center my-5">
        <Preloader/>
      </div>
      <div v-else-if="conference.moderatorArticles.length">
        <h4 class="text-center mb-4">Список поданных статей</h4>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>№</th>
              <th>Название статьи</th>
              <th>Автор</th>
              <th>Телефон</th>
              <th>Секция</th>
              <th>Язык</th>
              <th>Статьи</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(userChance, userChanceIndex) in conference.moderatorArticles" :key="userChanceIndex">
              <td>{{userChanceIndex+1}}</td>
              <td>{{userChance.theme}}</td>
              <td>{{userChance.last_name}} {{userChance.first_name}} {{userChance.middle_name}}</td>
              <td>
                <div v-if="userChance.phone == null">
                  -
                </div>
                <div v-else>
                  {{userChance.phone}}
                </div>
              </td>
              <td>{{userChance.conference_section_name}}</td>
              <td>{{userChance.language}}</td>
              <td>
                <table class="table table-sm">
                  <tbody>
                  <tr>
                    <th>Дата получения</th>
                    <th>Файлы</th>
                    <th>Сооавторы</th>
                    <th>% плагиата (strike)</th>
                    <th>Действия/Статусы</th>

                  </tr>
                  <tr v-for="(userArticle, userArticleIndex) in userChance.user_articles" :key="userArticleIndex">
                    <td>{{convertGraduateWorkTimestampToDate(userArticle.created_at)}}</td>
                    <td>
                      <div class="my-1">
                        <a :href="`${userArticle.article_file}`">
                          Версия {{userArticleIndex+1}}
                        </a>
                      </div>
                      <div class="my-1">
                        <a :href="`${userArticle.literature_file}`">
                          Список литературы {{userArticleIndex+1}}
                        </a>
                      </div>
                    </td>
                    <td>
                    <div v-for="(coauthor, idx) in userArticle.coauthor" :key="'coauthor'+idx" class="mb-2">
                      {{coauthor.last_name}} {{coauthor.first_name}}
                    </div>
                    </td>
                    <td>{{userArticle.strike_plagiat||'Результата нет'}}</td>
                    <td>
                      <div v-if="userArticle.status.length">
                        <Button label="Статусы" class="p-button-secondary p-button-text m-1"
                                @click="openStatuses(userChance.author_id, userArticle.conference_article_id)"/>
                      </div>
                      <div v-if="!userArticle?.section_moderator_lastname">
                        <Button icon="pi pi-check" class="p-button-rounded p-button-success m-1"
                                @click="openConfirmArticle(userArticle.conference_article_id)"/>
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger m-1"
                                @click="openRejectArticle(userArticle.conference_article_id)"/>
                      </div>
                      <div v-else>
                        <a href="#" @click="openUpdateSectionModeratorDialog(userArticle.conference_section_moderator_id)">
                          {{userArticle?.section_moderator_lastname}} {{userArticle?.section_moderator_firstname}}
                        </a>
                      </div>

<!--                      <Button label="Модератор секции" class="p-button-secondary p-button-text m-1"-->
<!--                              @click="openUpdateSectionModeratorDialog(userArticle.conference_article_id)"/>-->
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div v-else>
        <h4 class="text-center mb-4">Здесь пусто</h4>
      </div>


      <!-- confirm dialog -->
      <Dialog header="Подтверждение" v-model:visible="displayConfirmArticle" :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div class="px-1">
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" id="confirmStatusConfirm"
                   :value="articleStatuses['sectionModerator']"
                   v-model="conferenceArticleStatusId">
            <label class="form-check-label" for="confirmStatusConfirm">
              Подтвердить
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" id="confirmStatusForRevision"
                   :value="articleStatuses['submittedForEditingByConferenceModerator']"
                   v-model="conferenceArticleStatusId">
            <label class="form-check-label" for="confirmStatusForRevision">
              Отправить на доработку
            </label>
          </div>

          <div v-if="conferenceArticleStatusId" class="mt-4 mb-2">

            <!-- section moderator -->
            <div v-if="+conferenceArticleStatusId===articleStatuses['sectionModerator']">
              <p>Выберите модератора секции</p>
              <div class="input-group mb-3">
                <select class="form-control" v-model="sectionModeratorId">
                  <option v-for="(sectionModerator, sectionModeratorIndex) in conference.sectionModerators"
                          :value="sectionModerator.id"
                          :key="sectionModeratorIndex">
                    {{sectionModerator.lastname}} {{sectionModerator.firstname}}
                  </option>
                </select>
              </div>
            </div>
            <!-- end section moderator -->

            <div v-else-if="+conferenceArticleStatusId===articleStatuses['submittedForEditingByConferenceModerator']">
              <label for="forRevisionArticleComment" class="form-label">Комментарий</label>
              <textarea v-model="conferenceArticleCommentText" class="form-control" id="forRevisionArticleComment"
                        rows="3"></textarea>
            </div>
          </div>

        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeConfirmArticle"/>
          <Button :label="btnPostConferenceArticleCommentStatus?'Сохранение':'Отправить'" icon="pi pi-check" autofocus
                  @click="postConferenceArticleComment('confirm')"
                  :disabled="btnPostConferenceArticleCommentStatus||!conferenceArticleStatusId"/>
        </template>
      </Dialog>
      <!-- end confirm dialog -->


      <!-- reject dialog -->
      <Dialog header="Отклонение" v-model:visible="displayRejectArticle" :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div class="px-1">
          <div class="mb-3">
            <label for="rejectArticleComment" class="form-label">Комментарий</label>
            <textarea v-model="conferenceArticleCommentText" class="form-control" id="rejectArticleComment"
                      rows="3"></textarea>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeRejectArticle"/>
          <Button :label="btnPostConferenceArticleCommentStatus?'Сохранение':'Отказать'" icon="pi pi-check"
                  class="p-button-danger" autofocus
                  @click="postConferenceArticleComment('reject')"
                  :disabled="btnPostConferenceArticleCommentStatus||!conferenceArticleStatusId||(conferenceArticleStatusId==articleStatuses['sectionModerator']&&sectionModeratorId==0)"/>
        </template>
      </Dialog>
      <!-- end reject dialog -->

      <!-- statuses modal -->
      <Dialog header="Статусы" v-model:visible="displayStatuses"
              :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div v-for="(status, statusIndex) in statusesByArticle" :key="statusIndex">
            <div class="my-1">
              <div class="fw-bold">
                {{statusIndex+1}}. {{status.name}}
              </div>
              <div class="ms-2">
                Отправитель: {{status.lastname}} {{status.firstname}}
              </div>
              <div v-if="status.text" class="ms-2">
                Комментарий: {{status.text}}
              </div>
              <hr>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeStatuses"/>
        </template>
      </Dialog>
      <!-- end statuses modal -->


      <!-- update section moderator dialog -->
      <Dialog header="Поменять модератора секции" v-model:visible="displayUpdateSectionModerator" :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div class="mx-1">
            <div class="my-2 row">
              <div class="col-md-3">
                Модератор секции
              </div>
              <div class="col-md-9">
                <select class="form-control form-select" v-model="updateSectionModeratorId">
                  <option v-for="(item, index) in conference.sectionModerators" :value="item.id" :key="index">
                    {{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>

          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeUpdateSectionModeratorDialog"/>
          <Button label="Обновить" icon="pi pi-check" @click="putSectionModerator"/>
        </template>
      </Dialog>
      <!-- end update section moderator dialog -->

    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"

  export default {
    name: "ConferenceModerator",
    data() {
      return {
        loading: true,
        articleStatuses: {
          'deniedByConferenceModerator': 2,
          'submittedForEditingByConferenceModerator': 3,
          'sectionModerator': 4,
        },
        sectionModeratorId: 0,
        articleId: 0,
        conferenceArticleStatusId: 0,
        conferenceArticleCommentText: '',
        displayConfirmArticle: false,
        displayRejectArticle: false,
        btnPostConferenceArticleCommentStatus: false,
        displayStatuses: false,
        author_id: 0,
        conference_article_id: 0,
        displayUpdateSectionModerator: false,
        updateSectionModeratorId: 0,
        updateConferenceArticleId: 0
      }
    },
    computed: {
      ...mapState('conferenceModerator', ['conference']),
      statusesByArticle() {
        let statuses = null
        const authorArticles = this.conference.moderatorArticles.filter(i => i.author_id == this.author_id)
        authorArticles.forEach( a => {
          if(!statuses){
            statuses = a?.user_articles.find(i => i.conference_article_id == this.conference_article_id)?.status
          }
        })
        return statuses || []
      }
    },
    methods: {
      ...mapActions('conferenceModerator', ['GET_ARTICLES', 'POST_CONFERENCE_ARTICLE_COMMENT', 'GET_SECTION_MODERATORS',
        'POST_BIND_SECTION_MODERATOR','PUT_SECTION_MODERATOR']),
      ...mapMutations('conferenceModerator', ['UPDATE_CONFERENCE_SECTION_MODERATOR_ID']),
      convertGraduateWorkTimestampToDate,
      openStatuses(author_id, conference_article_id) {
        this.author_id = author_id
        this.conference_article_id = conference_article_id
        this.displayStatuses = true
      },
      closeStatuses() {
        this.displayStatuses = false
      },
      openConfirmArticle(articleId) {
        this.clearComment(articleId)
        this.displayConfirmArticle = true
      },
      closeConfirmArticle() {
        this.displayConfirmArticle = false
      },
      openRejectArticle(articleId) {
        this.clearComment(articleId, this.articleStatuses['deniedByConferenceModerator'])
        this.displayRejectArticle = true
      },
      closeRejectArticle() {
        this.displayRejectArticle = false
      },
      async postConferenceArticleComment(action) {
        this.btnPostConferenceArticleCommentStatus = true
        const postData = {
          conference_article_id: this.articleId,
          conference_article_status_id: this.conferenceArticleStatusId,
          text: +this.conferenceArticleStatusId !== this.articleStatuses['sectionModerator'] ? this.conferenceArticleCommentText : null,
          section_moderator_id: this.sectionModeratorId
        }

        let status = false

        if (+this.conferenceArticleStatusId === this.articleStatuses['sectionModerator']) {
          status = await this.POST_BIND_SECTION_MODERATOR(postData)
        } else {
          status = await this.POST_CONFERENCE_ARTICLE_COMMENT(postData)
        }

        if (status) {
          await this.GET_ARTICLES()
          action === 'confirm' ? this.closeConfirmArticle() : this.closeRejectArticle()
          this.$message({text: 'Сохранено'})
        }

        this.btnPostConferenceArticleCommentStatus = false
      },
      clearComment(articleId, status = 0) {
        this.sectionModeratorId = 0
        this.articleId = articleId
        this.conferenceArticleCommentText = ''
        this.conferenceArticleStatusId = status
      },
      openUpdateSectionModeratorDialog(conference_section_moderator_id) {
        this.displayUpdateSectionModerator = true
        //this.updateConferenceSectionModeratorId = conference_section_moderator_id
        this.UPDATE_CONFERENCE_SECTION_MODERATOR_ID(conference_section_moderator_id)
      },
      closeUpdateSectionModeratorDialog() {
        this.displayUpdateSectionModerator = false
        this.updateSectionModerator = 0
      },

      async putSectionModerator() {

        const putData = {
          section_moderator_id: this.updateSectionModeratorId
        }
        let data = await this.PUT_SECTION_MODERATOR(putData)

        if (data) {
          this.displayUpdateSectionModerator = false
          this.updateSectionModeratorId = 0
          await this.GET_ARTICLES()
          this.$message({text: 'Обновлено'})
        }
      }
    },
    async mounted() {
      await this.GET_ARTICLES()
      await this.GET_SECTION_MODERATORS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>
